import React, { useEffect, useState } from 'react';

//styles
import classes from './services.module.css';

function Percent({name, percent}) {

    return (
        <div className={classes.percentItem}>
            <p className={classes.percentItemTitle}>{name}</p>
            <div className={classes.percentItemDiv}>
                <div className={classes.percentItemMain} style={{width: `${percent}%`}}>{percent}%</div>
            </div>
        </div>
    );
}

export default Percent;