const bitcoin = require('../images/currencies/bitcoin.png');
// const etherum = require('../images/currencies/Etherum.png');
// const ltc = require('../images/currencies/ltc.png');
// const luna = require('../images/currencies/luna.png');
const usdt = require('../images/currencies/usdt.png');
// const solana = require('../images/currencies/solana.png');
const busd = require('../images/currencies/busd.png');

const coins = [
    {
        name: 'Bitcoin',
        image: bitcoin.default,
        network: [
            'BTC'
        ],
        address: '1BFLFBXnnR1bWQE3KaT7G8EMNBYb1Gh9Mj'
    },
    // {
    //     name: 'Ethereum',
    //     image: etherum.default,
    //     network: [
    //         'ERC (20)'
    //     ],
    //     address: '0xA3340C3391c3dAB7a54072361FB9291e4459B9Ec'
    // },
    {
        name: 'BUSD',
        image: busd.default,
        network: [
            'ERC (20)'
        ],
        address: '0x78aa16947f9ee0a959e60b382ccd414f294d4bb2'
    },
    // {
    //     name: 'Luna',
    //     image: luna.default,
    //     network: [
    //         'LUN'
    //     ],
    //     address: '0xA3340C3391c3dAB7a54072361FB9291e4459B9Ec'
    // },
    {
        name: 'USDT',
        image: usdt.default,
        network: [
            'TRC (20)',
        ],
        address: 'TZ7hz8gCLrh5gFMDB6AkPiVJxJ7BpQ4QBm'
    },
    // {
    //     name: 'Solana',
    //     image: solana.default,
    //     network: [
    //         'SOL'
    //     ],
    //     address: '0xA3340C3391c3dAB7a54072361FB9291e4459B9Ec'
    // },
];

export default coins