const img1 = require('../images/Clients/02.jpg').default;
const img2 = require('../images/Clients/03.jpg').default;
const img3 = require('../images/Clients/04.jpg').default;
const img4 = require('../images/Clients/05.jpg').default;

const clients = [
    {
        image: img2,
        name: 'Sam Urwin',
        content: 'My experience with Apexcorp has been a very positive one. The level of knowledge, professionalism and customer care are unmatched'
    },
    {
        image: img1,
        name: 'Linda Smith',
        content: 'Apexcorp has constantly demonstrated excellence in every aspect in their role as my investment advisors and in their relationship with me as their client'
    },
    {
        image: img3,
        name: 'Dennis Eaton',
        content: 'I have been investing with this platform for three months now, and I would say It is recommendable to every investor who choses to invest from any country'
    },
    {
        image: img4,
        name: 'Albert Meskill',
        content: 'They provide one of the best customer service which makes it really easy to get started'
    },
];

export default clients;