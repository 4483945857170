import React from 'react';

//styles
import classes from './openTransaction.module.css';

//components
import coins from '../../../../assets/data/coins';
import { FiX } from 'react-icons/fi';

function OpenTransaction({data, close}) {
    const coinImage = coins.findIndex(i => i.name === data.coin);

    let walletAddress;
    if(data.walletAddress){
        walletAddress = (
            <div className={classes.item}>
                <div className={classes.item_1}>Wallet address</div>
                <div className={classes.item_2}><p>{data.walletAddress}</p></div>
            </div>
        );
    };

    let wallet;
    if(data.wallet){
        wallet = (
            <div className={classes.item}>
                <div className={classes.item_1}>Wallet name</div>
                <div className={classes.item_2}><p>{data.wallet.name}</p></div>
            </div>
        )
    }
    
    return (
        <div className={classes.container}>
            <div className={classes.maincontainer}>
                <div className={classes.cancle_div} onClick={close}> <FiX /> </div>
                <div className={classes.main}>
                    <p className={classes.divTitle}>Transaction Details</p>
                    <div className={classes.top}>
                        <img src={coins[coinImage].image} />
                        <div className={classes.amount_div}>
                            <p className={classes.amount}>{data.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</p>
                            <p className={classes.amount_type}>USD</p>
                        </div>
                    </div>

                    <div className={classes.items_container}>
                        <div className={classes.item}>
                            <div className={classes.item_1}>Coin name</div>
                            <div className={classes.item_2}>{data.coin}</div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.item_1}>Transaction</div>
                            <div className={classes.item_2}>{data.type}</div>
                        </div>

                        {data.wallet && wallet}
                        {data.walletAddress && walletAddress}
                        
                        <div className={classes.item}>
                            <div className={classes.item_1}>Date</div>
                            <div className={classes.item_2}>{data.date}</div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.item_1}>Status</div>
                            <div className={`${classes.item_2} ${classes.capitailize}`}>{data.status}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpenTransaction;