import React from 'react';

//components
import { Header, SliderWidget } from '../../components';
import AboutUs from './components/about/AboutUs';
import AwesomeFact from './components/awesomefacts/AwesomeFact';
import Brains from './components/brains/Brains';
import Calculator from './components/calculator/Calculator';
import Chart from './components/chart/Chart';
import Clients from './components/clients/Clients';
import Contact from './components/contactus/Contact';
import Coursel from './components/coursel/Coursel';
import Intro from './components/intro/Intro';
import Pricing from './components/pricing/Pricing';
import Services from './components/services/Services';
import WhyApexcorp from './components/whyApexcorp/WhyApexcorp';

//images
import telegram from '../../assets/images/icons/telegram.png';
import whatsapp from '../../assets/images/icons/whatsapp.png';

//styles
import classes from './home.module.css';

function Home(props) {
    return (
        <div className={classes.homePage}>
            <Header active={'Home'} toggle={() => console.log('')}/>
            <Coursel />
            <SliderWidget />
            <Intro />
            <Services />
            <Pricing />
            <AboutUs />
            <WhyApexcorp />
            <Calculator />
            <Brains />
            <Clients />
            <AwesomeFact />
            <Chart />
            <Contact />
            <div className={classes.footer}>
                <p>Copyright © 2022 Apexcorp Investment</p>
            </div>
            <div className={classes.links}>
                <a href='https://t.me/Farnoosh_Torabi' target='_blank'>
                    <div className={classes.link_item}>
                        <img src={telegram} alt='' /> Telegram
                    </div>
                </a>
                <a href='https://wa.me/12103299726' target='_blank'>
                    <div className={classes.link_item}>
                        <img src={whatsapp} alt='' /> WhatsApp
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Home;