import React, { useEffect } from 'react';

//components
import { DashboardHeader, EmptyScreen, Loader, Navigation } from '../../components';
import Box from './components/box/Box';
import * as actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Transactions from './components/transactions/Transactions';
import OpenTransaction from './components/openTransaction/OpenTransaction';

//styles
import classes from './dashboard.module.css';
import { useState } from 'react';

function Dashboard(props) {
    const dispatch = useDispatch();

    //redux state
    const userData = useSelector(state => state.userData);
    const loading = useSelector(state => state.loading);

    //local state
    const [showTransactions, setShowTransactions] = useState(null);

    useEffect(() => {
        dispatch(actions.fetchData());
    }, []);

    useEffect(() => {
        if(userData) console.log(userData.transactions);
    }, [userData])

    let container;
    if(userData){
        container = (
            <section className={classes.section}>
                <DashboardHeader title={'Dashboard'} username={userData?.fullname} image={userData?.image} />
                <div className={classes.boxContainer}>
                    <Box name={'Balance'} value={userData?.balance} />
                    <Box name={'Total Deposit'} value={userData?.deposit} />
                    <Box name={'Amount Earned'} value={userData?.roi} />
                    <Box name={'Withdrawn'} value={userData?.withdraw} />
                </div>
                <Transactions data={userData?.transactions} onSelect={(e) => setShowTransactions(e)} />
            </section>
        )
    }else{
        container = <EmptyScreen />
    }

    return (
        <Navigation active={'Dashboard'}>
            {container}
            {loading && <Loader />}
            {showTransactions && <OpenTransaction data={showTransactions} close={() => setShowTransactions(null)} />}
        </Navigation>
    );
}

export default Dashboard;