import React, { useEffect, useState } from 'react';

//styles
import classes from './calculator.module.css';

//icons
import { HiSwitchHorizontal } from 'react-icons/hi';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

function Calculator(props) {
    AOS.init();

    //local state
    // Initializing all the state variables 
    const [info, setInfo] = useState([]);
    const [input, setInput] = useState(0);
    const [displayInput, setDisplayInput] = useState(0);
    const [from, setFrom] = useState("usd");
    const [to, setTo] = useState("btc");
    const [options, setOptions] = useState([]);
    const [output, setOutput] = useState(0);

     // Calling the api whenever the dependency changes
     useEffect(() => {
        axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`)
        .then((res) => {
            setInfo(res.data[from]);
        })
    }, [from]);

    useEffect(() => {
        setOptions(Object.keys(info));
        convert();
    }, [info]);

    function convert() {
        var rate = info[to];
        setOutput(input * rate);
        setDisplayInput(input);
    }

    return (
        <div className={classes.container} >
            <h2>Currency <span>Converter</span></h2>
            <div className={classes.main} data-aos="fade-up">
                <div className={classes.firstInput}>
                    <p className={classes.inputTitle}>Amount</p>
                    <input type={'number'} onChange={(e) => setInput(e.target.value)} />
                </div>
                <div className={classes.convertGrid}>
                    <div className={classes.from}>
                        <p className={classes.inputTitle}>From</p>
                        <select onChange={(i) => setFrom(i.target.value)}>
                        {options.map(i => <option selected={i === 'usd' && true } >{i}</option>)}
                        </select>
                    </div>
                    <div className={classes.convertIcon}>
                        <HiSwitchHorizontal size="30px"/>
                    </div>
                    <div className={classes.from}>
                        <p className={classes.inputTitle}>To</p>
                        <select onChange={(i) => setTo(i.target.value)}>
                        {options.map(i => <option className='Currency_item_p' selected={i === 'btc' && true } style={{textTransform: 'uppercase'}} >{i}</option>)}
                        </select>
                    </div>
                </div>
                <div className={classes.results}>
                    <p>{displayInput+""+from+" = "+output.toFixed(7) + "" + to}</p>
                </div>
                <div className={classes.convert} onClick={convert}>
                    Convert
                </div>
            </div>
        </div>
    );
}

export default Calculator;