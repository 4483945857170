import React from 'react';

//styles
import classes from './awesomefacts.module.css';

//icons
import {BsCashStack} from 'react-icons/bs';
import {FiUsers, FiUserPlus, FiUser} from 'react-icons/fi';

//image icon
import bitcoin from '../../../../assets/images/bitcoin.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

function AwesomeFact(props) {
    AOS.init();

    return (
        <div className={classes.container}>
            <h2>Apexcorp Investment Awesome <span>Facts</span></h2>
            <p className={classes.intro}>Here are some facts about us that you might find interesting</p>
            
            <div className={classes.canva}>
                <img src={bitcoin} />
            </div>
            
            <div className={classes.main} data-aos="fade-up">
                <div className={classes.item}>
                    <div className={classes.itemMain}>
                        <p>$15,905,328</p>
                        <BsCashStack className={classes.icon} />
                    </div>
                    <p className={classes.itemTitle}>Profit Paid</p>
                </div>
                <div className={classes.item}>
                    <div className={classes.itemMain}>
                        <p>10,890</p>
                        <FiUsers className={classes.icon} />
                    </div>
                    <p className={classes.itemTitle}>Happy Clients</p>
                </div>
                <div className={classes.item}>
                    <div className={classes.itemMain}>
                        <p>1,952</p>
                        <FiUserPlus className={classes.icon} />
                    </div>
                    <p className={classes.itemTitle}>Days and Counting</p>
                </div>
                <div className={classes.item}>
                    <div className={classes.itemMain}>
                        <p>12,420</p>
                        <FiUser className={classes.icon} />
                    </div>
                    <p className={classes.itemTitle}>Total Investors</p>
                </div>
            </div>
        </div>
    );
}

export default AwesomeFact;