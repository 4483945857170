import React from 'react';

//styles
import classes from './coursel.module.css';

//components
import { useHistory } from 'react-router-dom';

import { data } from './data';
import { useState } from 'react';
import { useEffect } from 'react';

const CourselItem = ({image, title, content, active}) => {
    const history = useHistory();

    return(
        <div className={active ? classes.activeBox : classes.inactiveBox}>
            <div className={classes.container} style={{backgroundImage: `url(${image})`}}>
                <div className={classes.main}>
                    <div>
                        <h1>{title}</h1>
                        <p>{content}</p>
                        <div className={classes.getStartedInput}>
                            <input placeholder='Email address ...' />
                            <div className={classes.button} onClick={() => history.push('/accounts')}>Get Started</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 

function Coursel(props) {
    const [active, setActive] = useState(0);

    useEffect(() => {
        updateActive()
    }, [active]);

    const updateActive = () => {
        setTimeout(() => {
            if(active === 3){
                setActive(0);
            }else{
                setActive(prev => prev + 1);
            }
        }, 10000)
    }
    
    return (
        <>
            {data.map((i, idx) => (
                <CourselItem 
                    image={i.image} 
                    title={i.title} 
                    content={i.content}
                    active={active === idx}
                />
            ))}
        </>
    );
}

export default Coursel;