const SETTOKEN = 'SETTOKEN';
const SETLOADING = 'SETLOADING';
const SETVERIFYSCREEN = 'SETVERIFYSCREEN';
const SETERRORMESSAGE = 'SETERRORMESSAGE';
const SETUSERDATA = 'SETUSERDATA';
const SETSUCCESSSCREEN = 'SETSUCCESSSCREEN';
const RESETEMAIL = 'RESETEMAIL';
const SETREFERRALS = 'SETREFERRALS';

export {
    SETTOKEN,
    SETLOADING,
    SETVERIFYSCREEN,
    SETERRORMESSAGE,
    SETUSERDATA,
    SETSUCCESSSCREEN,
    RESETEMAIL,
    SETREFERRALS
}