import React from 'react';

import classes from './loader.module.css';

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { ThreeDots } from  'react-loader-spinner'

function Loader(props) {
    return (
        <div className={classes.container}>
            <div class={classes.lds_ring}><div></div><div></div><div></div><div></div></div>
        </div>
    );
}

export default Loader;