import React from 'react';
import { useEffect } from 'react';

//styles
import classes from './chart.module.css';

function Chart(props) {

    useEffect(() => {
        getWidget()
    }, []);

    const getWidget = () => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "100%",
            "height": 490,
            "defaultColumn": "overview",
            "screener_type": "crypto_mkt",
            "displayCurrency": "USD",
            "colorTheme": "light",
            "locale": "en"
        });
        document.getElementById("myContainer").appendChild(script);
    }

    return (
        <div className={classes.container}>
            <div id="myContainer">
                <div className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chart;

// <!-- TradingView Widget BEGIN -->
// <div class="tradingview-widget-container">
//   <div class="tradingview-widget-container__widget"></div>
//   <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/" rel="noopener" target="_blank"><span class="blue-text">Cryptocurrency Markets</span></a> by TradingView</div>
//   <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-screener.js" async>
//   {
//   "width": "100%",
//   "height": "100%",
//   "defaultColumn": "overview",
//   "screener_type": "crypto_mkt",
//   "displayCurrency": "USD",
//   "colorTheme": "light",
//   "locale": "en"
// }
//   </script>
// </div>
// <!-- TradingView Widget END -->