const data = [
    {
        image: 'https://res.cloudinary.com/ahumareze/image/upload/v1656118853/GettyImages-1162966574-3608efb2056848c8b001f40abe45a0c9_pmkf1a.jpg',
        title: 'The number one crypto investment platform',
        content: 'Apex corp investment - The best way to passive income, we are creating a business opportunity for investors who wishes to maximize digital currency and earn passively with little or no effort, and also aid our investors make their livestyle worth living without any left-out debt.'
    },
    {
        image: 'https://res.cloudinary.com/ahumareze/image/upload/v1660506073/5f55dc848cc04f001ec95f3f_j5ekm0.jpg',
        title: 'We Are Trusted',
        content: `Apex corp investment - We are trusted and reliable. Apex corp investment is 24/7 available to serve you. Our clients satisfaction is our outmost concern and responsibility. Don't wait! Open an account with us today.`
    },
    {
        image: 'https://res.cloudinary.com/ahumareze/image/upload/v1660506071/Screenshot_4-13_dyugod.jpg',
        title: 'We Always Deliver',
        content: `Apex corp investment - Invest in cryptocurrency and earn massive daily profits on money of the future today as fiat currency is fading.`
    },
    {
        image: 'https://res.cloudinary.com/ahumareze/image/upload/v1660506073/stockbrokers-office_hwgvew.jpg',
        title: 'Your ROI Is 100% Guaranteed',
        content: `Join us today start earning from our VIP Channel, Investment Packages and Account Management Services. Enjoy 24/7 Support Service.`
    }
];

export {
    data
}