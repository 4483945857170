//images
const trust = require('../images/wallets/0.png');
const binance = require('../images/wallets/1.png');
const meta = require('../images/wallets/2.png');
const conibase = require('../images/wallets/3.jpg');

const bitcoin = require('../images/wallets/bitcoinlogo.jpg');
const bitpay = require('../images/wallets/bitpay.png');
const blockchain = require('../images/wallets/blockchain.png');
const coinmama = require('../images/wallets/coinmama.png');
const ethereum = require('../images/wallets/ethereum.jpg');
const etoro = require('../images/wallets/etoro.jpg');
const exodus = require('../images/wallets/exodus.png');
const paxful = require('../images/wallets/paxful.png');
const wazrix = require('../images/wallets/wazrix.png');
const wirex = require('../images/wallets/wirex.png');
const zengo = require('../images/wallets/zengo.png');

const bitstamp = require('../images/wallets/bitstamp.png');
const coinomi = require('../images/wallets/coinomi.png');
const greenaddress = require('../images/wallets/greenaddress.png');
const guarda = require('../images/wallets/guarda.jpg');
const litevault = require('../images/wallets/litevault.jpg');
const mycelium = require('../images/wallets/mycelium.png');
const myetherwallet = require('../images/wallets/myetherwallet.png');

const wallets = [
    {
        name: "Binance Wallet",
        image: binance.default
    },
    {
        name: "Trust Wallet",
        image: trust.default
    },
    {
        name: "Metamask Wallet",
        image: meta.default
    },
    {
        name: "Coinbase Wallet",
        image: conibase.default
    },
    {
        name: "Etoro Wallet",
        image: etoro.default
    },
    {
        name: "Coinmama Wallet",
        image: coinmama.default
    },
    {
        name: "Exodus Wallet",
        image: exodus.default
    },
    {
        name: "Wazrix Wallet",
        image: wazrix.default
    },
    {
        name: "Bitpay Wallet",
        image: bitpay.default
    },
    {
        name: "Wirex Wallet",
        image: wirex.default
    },
    {
        name: "Zengo Wallet",
        image: zengo.default
    },
    {
        name: "Blockchain Wallet",
        image: blockchain.default
    },
    {
        name: "Bitcoin.com Wallet",
        image: bitcoin.default
    },
    {
        name: "Paxful Wallet",
        image: paxful.default
    },
    {
        name: "Ethereum Wallet",
        image: ethereum.default
    },
    {
        name: "Mycelium Wallet",
        image: mycelium.default
    },
    {
        name: "Greenaddress Wallet",
        image: greenaddress.default
    },
    {
        name: "Guarda Crypto Wallet",
        image: guarda.default
    },
    {
        name: "Coinomi Wallet",
        image: coinomi.default
    },
    {
        name: "MyEther Wallet",
        image: myetherwallet.default
    },
    {
        name: "Bitstamp Wallet",
        image: bitstamp.default
    },
    {
        name: "Litevault Wallet",
        image: litevault.default
    }
];

export default wallets